<template>
  <div class="user-manage-container">
    <!-- <el-card class="header">
      <search @search="searchClick" @clear="clearClick"></search>
    </el-card> -->
    <el-card>
      <div class="table-head-box">
        <el-button
          style="float: right"
          :icon="Refresh"
          size="small"
          round
          @click="getListData"
        ></el-button>
      </div>

      <el-table
        ref="multipleTable"
        :data="tableData"
        v-loading="loading"
        border
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column
          prop="id"
          label="ID"
          width="150"
          align="center"
          fixed="left"
        />
        <el-table-column
          prop="content"
          label="内容"
          min-width="250"
          align="center"
          fixed="left"
          show-overflow-tooltip
        />
        <el-table-column label="图片" width="150" align="center">
          <template #default="{ row }">
            <el-image
              style="width: 100px; height: 100px; border-radius: 6px"
              :src="row.imgUrl"
              :fit="'contain'"
              :preview-src-list="[row.imgUrl]"
              :preview-teleported="true"
            />
          </template>
        </el-table-column>

        <el-table-column label="创建时间" min-width="180">
          <template #default="{ row }">
            {{ $filters.dateTimeFilter(row.createTime) }}
          </template>
        </el-table-column>

        <el-table-column label="操作" fixed="right" width="170">
          <template #default="{row}">
            <el-button
              v-permission="['listMessageReply']"
              size="small"
              @click="toDetail(row)"
            >
              详情
            </el-button>

            <!-- <el-dropdown style="margin-left: 12px;">
              <el-button size="small">
                <span class="el-dropdown-link">
                  更多 <ArrowDown class="el-icon" style="margin-left: 5px;" />
                </span>
              </el-button>
              <template #dropdown>
                <el-dropdown-menu class="user-dropdown">
                  <el-dropdown-item>
                    <el-button
                      v-permission="['deleteOrder']"
                      type="danger"
                      size="small"
                      style="width: 72px"
                      @click="handleDelete(row)"
                    >
                      删除
                    </el-button>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown> -->
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        class="pagination"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[2, 5, 10, 20, 50, 100, 500, 1000]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script setup>
import { ref, onActivated, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import * as messageApi from '@/api/message.js'
import { ElMessageBox, ElMessage } from 'element-plus'
import { ArrowDown, Refresh } from '@element-plus/icons-vue'
import search from './components/search.vue'

const store = useStore()

// 数据相关
const tableData = ref([])
const multipleTable = ref(null)
const total = ref(0)
const page = ref(1)
const size = ref(10)
const searchFrom = ref({})
const loading = ref(false)
// 获取数据的方法
const getListData = async () => {
  loading.value = true
  const result = await messageApi.messagelist({
    current: page.value,
    size: size.value,
    ...Object.fromEntries(
      Object.entries(searchFrom.value).filter(
        item => item[1] !== '' || item[1] === 0
      )
    )
  })
  tableData.value = result.items
  total.value = result.total
  loading.value = false
}

// 处理导入用户后数据不重新加载的问题
onActivated(getListData)

const selectedIndexs = ref([])
const handleSelectionChange = e => {
  selectedIndexs.value = e
}

// 分页相关
/**
 * size 改变触发
 */
const handleSizeChange = currentSize => {
  size.value = currentSize
  getListData()
}

/**
 * 页码改变触发
 */
const handleCurrentChange = currentPage => {
  page.value = currentPage
  getListData()
}

// 搜索
const searchClick = e => {
  page.value = 1
  searchFrom.value = e
  getListData()
}
// 清空
const clearClick = () => {
  page.value = 1
  searchFrom.value = {}
  getListData()
}

const router = useRouter()
/**
 * 进入留言详情页
 */
const toDetail = row => {
  console.log('进入留言详情页')
  const data = {
    content: row.content,
    imgUrl: row.imgUrl,
    openid: row.openid
  }
  const routerUrl = router.resolve({
    path: `/message/reply/${row.id}/${encodeURIComponent(JSON.stringify(data))}`
  })
  window.open(routerUrl.href, '_blank')
}
</script>

<style lang="scss" scoped>
.user-manage-container {
  .header {
    margin-bottom: 22px;
    text-align: right;
  }
  ::v-deep .avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }

  ::v-deep .el-tag {
    margin-right: 6px;
  }

  .pagination {
    margin-top: 20px;
    text-align: center;
  }
}

.table-head-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 14px;
  .button {
    margin: 0 5px;
  }
}
</style>
