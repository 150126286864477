import request from '@/utils/request'

const baseUrl = '/manager/comment'

/**
 * 留言列表
 */
export const messagelist = data => {
  return request({
    url: `${baseUrl}/main-page`,
    params: data
  })
}

/**
 * 留言回复列表
 */
export const messageReplyList = data => {
  return request({
    url: `${baseUrl}/reply-detail`,
    params: data
  })
}

/**
 * 客服回复留言
 */
export const addReply = data => {
  return request({
    url: `${baseUrl}/reply-main`,
    method: 'POST',
    data
  })
}
